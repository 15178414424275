const auth0CustomclaimsNamespace = 'customclaims_';
export const constants = {
  AUTH0_METADATA_EXTERNAL_USER_CREATED: `${auth0CustomclaimsNamespace}external_user_created`,
  AUTH0_METADATA_ID: `${auth0CustomclaimsNamespace}external_id`,
  AUTH0_METADATA_ROLES: `${auth0CustomclaimsNamespace}roles`,
  AUTH0_ROLE_ADMIN: 'Admin',
  SHARE_ACTION_COPY_CONTENT: 'copyContent',
  SHARE_ACTION_COPY_LINK: 'copyLink',
  SHARE_ACTION_PDF: 'pdf',
};
Object.freeze(constants);
