import { usePropertyStore } from '../store';
import { usePropertyApi } from './usePropertyApi';

export async function setActivePropertyFunc(property) {
  // Check if the active property is different than the new one to avoid unnecessary API calls
  if (usePropertyStore().activeProperty?.publicId !== property.publicId) {
    // Load property config
    const propertyConfig = await usePropertyApi().getPropertyConfig(property.publicId);
    usePropertyStore().setActiveProperty(propertyConfig);
  }
}
