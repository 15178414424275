import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useUserStore = defineStore('user', () => {
  // STATE
  const userEmail = ref('');
  const userProperties = ref([]);

  // ACTIONS
  function setUserEmail(user) {
    userEmail.value = user.email;
  }

  function setUserProperties(user) {
    userProperties.value = user.properties;
  }

  function $reset() {
    userEmail.value = '';
    userProperties.value = [];
  }

  return {

    // STATE
    userEmail,
    userProperties,

    // ACTIONS
    setUserEmail,
    setUserProperties,
    $reset,
  };
});
