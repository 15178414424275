import axios from 'axios';
import { useAuthStore } from '../../../store/auth';
import { useAppStore } from '../../../store/app';
import { constants } from '../../../utils/constants';

export const useUserApi = () => {
  const appStore = useAppStore();

  const addUser = async (idp_id, email) => {
    appStore.setIsLoading(true);
    const accessToken = await useAuthStore().getAuth0AccessToken();
    const userData = { idp_id, email };
    const headers = { authorization: `Bearer ${accessToken}` };

    try {
      await axios.post(`${import.meta.env.VITE_API_HOST}/user/add`, userData, { headers });
    } catch (error) {
      console.error('Error adding user', error);
    } finally {
      appStore.setIsLoading(false);
    }
  };

  const getUser = async () => {
    // If the ud is not in the token (as it'll happen in first login/signup), go fetch fresh one
    appStore.setIsLoading(true);
    const refreshTokenToGetFreshClaims = !useAuthStore().auth0User[constants.AUTH0_METADATA_ID];
    const accessToken = await useAuthStore().getAuth0AccessToken(refreshTokenToGetFreshClaims);

    try {
      const results = await axios.get(`${import.meta.env.VITE_API_HOST}/user`, {
        headers: { authorization: `Bearer ${accessToken}` },
      });
      return results.data;
    } catch (error) {
      console.log('Error getting user', error);
    } finally {
      appStore.setIsLoading(false);
    }
  };

  return { addUser, getUser };
};
