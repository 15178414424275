import {
  format, parseISO, addDays, differenceInDays,
} from 'date-fns';

export function formatDatePickerDate(datePickerDate) {
  return format(datePickerDate, 'yyyy-MM-dd');
}

export function formatDateForPdfDownload() {
  return format(new Date(), 'yyyy-MM-dd_kkmmss');
}

// function to format for user display like 25 - 27 June or 25 June - 1 July
export function formatDisplayDateRange(startDate, endDate) {
  const start = format(startDate, 'd');
  const end = format(endDate, 'd');
  const startMonth = format(startDate, 'MMM');
  const endMonth = format(endDate, 'MMM');
  if (startMonth === endMonth) {
    return `${start} - ${end} ${endMonth}`;
  }
  return `${start} ${startMonth} - ${end} ${endMonth}`;
}

export function formatDisplaySingleDate(date) {
  const start = format(date, 'd');
  const startMonth = format(date, 'MMM');
  return `${start} ${startMonth}`;
}

export function formatDisplaySingleDateWithTime(date) {
  return format(date, 'd MMM, HH:mm');
}

export function calculateDateDifference(startDate, endDate) {
  const difference = differenceInDays(formatDatePickerDate(endDate), formatDatePickerDate(startDate));

  return difference;
}

export function getCheckoutFromCheckinAndNights(checkIn, nights) {
  const checkInDate = parseISO(checkIn);
  const checkOutDate = addDays(checkInDate, +nights);
  return format(checkOutDate, 'yyyy-MM-dd');
}

export function getDateRangeArray(checkIn, checkOut) {
  if (!checkIn && !checkOut) {
    return [];
  }

  if (checkIn && !checkOut) {
    return [checkIn];
  }

  const startDate = new Date(checkIn);
  const endDate = new Date(checkOut);
  const dates = [];

  const currentDate = startDate;
  while (currentDate <= endDate) {
    dates.push(format(currentDate, 'yyyy-MM-dd'));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}

export function getNumberOfNightsFromDateRange(range) {
  if (!range || range.length === 0) {
    return null;
  }
  return range.length - 1;
}

export function addNightsToDate(dateString, nights) {
  // Parse the input date string to a Date object
  const date = parseISO(dateString);
  // Add the number of nights to the date
  const newDate = addDays(date, +nights);
  // Format the new date to the same format
  const formattedDate = format(newDate, 'yyyy-MM-dd');
  return formattedDate;
}
