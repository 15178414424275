// This function generates all possible room combinations for a given set of rooms and availability parameters.
export function getRoomCombinations(rooms, availabilityParams) {
  const { checkin, nights } = availabilityParams;

  // Step 1: Calculate the required dates
  const getRequiredDates = (startDate, nightsNumber) => Array.from({ length: nightsNumber }, (_, i) => {
    const date = new Date(startDate);
    date.setDate(date.getDate() + i);
    return date.toISOString().split('T')[0]; // YYYY-MM-DD format
  });

  const requiredDates = getRequiredDates(checkin, nights);

  // Step 2: Get available rooms for each date using `map` and `filter`
  const availableRoomsByDate = requiredDates.map((date) => rooms.filter((room) => room.rates.some((rate) => rate.days.some((day) => day.date === date && day.price !== ''))).map((room) => ({ roomId: room.id, roomName: room.name })));

  // Step 3: Generate combinations recursively without explicit loops
  const generateCombinations = (roomLists) => {
    if (roomLists.length === 0) return [[]];

    const [firstList, ...restLists] = roomLists;
    const restCombinations = generateCombinations(restLists);

    return firstList.flatMap((room) => restCombinations.map((combination) => [room, ...combination]));
  };

  const allCombinations = generateCombinations(availableRoomsByDate);

  // Step 4: Process the combinations to aggregate the number of nights per room
  const processedCombinations = allCombinations.map((combination) => {
    // Create a map to store the number of nights in each room
    const roomMap = combination.reduce((acc, room) => {
      const key = `${room.roomId}:${room.roomName}`;
      if (!acc[key]) {
        acc[key] = { roomId: room.roomId, roomName: room.roomName, nights: 0 };
      }
      acc[key].nights += 1;
      return acc;
    }, {});

    // Convert the roomMap to an array of objects with roomId, roomName, and nights
    return Object.values(roomMap);
  });

  return processedCombinations;
}
