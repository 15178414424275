import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useQuoteStore } from '../../Quote/store';

export const usePropertyStore = defineStore('property', () => {
  // STATE
  const activeProperty = ref({});

  // ACTIONS

  function setActiveProperty(property) {
    activeProperty.value = property;
    useQuoteStore().setThemeId(activeProperty.value.branding.defaultThemeId);
  }

  function $reset() {
    activeProperty.value = {};
  }

  return {
    // STATE
    activeProperty,

    // ACTIONS
    setActiveProperty,
    $reset,
  };
});
