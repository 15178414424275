<template>
  <div class="pa-4 text-center">
    <v-dialog
      v-model="isLoading"
      max-width="220"
      persistent
    >
      <v-list
        class="py-2"
        color="primary"
        elevation="12"
        rounded="lg"
      >
        <v-list-item

          title="Please wait..."
        >
          <template v-slot:append>
            <v-progress-circular
              color="primary"
              indeterminate="disable-shrink"
              size="16"
              width="2"
            ></v-progress-circular>
          </template>
        </v-list-item>
      </v-list>
    </v-dialog>
  </div>
</template>
<script setup >
import { storeToRefs } from 'pinia';
import { useAppStore } from '../store/app';

const store = useAppStore();

const { isLoading } = storeToRefs(store);
</script>
