/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
import '../assets/main.scss';
import { el, en } from 'vuetify/locale';

// Composables
import { createVuetify } from 'vuetify';

const hotelQuoteTheme = {
  dark: false,
  colors: {
    hqTextBackgroundForLightText: '#9f9f9f',
    hqTextBackgroundForSections: '#f7f7f7',
    hqTextBackgroundForExpansionPanel: '#f5f5f5',
    hqTextColorLight: '#fafafa',
    hqGreen: '#60ad5a',
    background: '#FFFFFF',
    hqWhite: '#FFFFFF',
    primary: '#075E9D',
    // surface: '#FFFFFF',
    // 'surface-bright': '#FFFFFF',
    // 'surface-light': '#EEEEEE',
    // 'surface-variant': '#424242',
    // 'on-surface-variant': '#EEEEEE',
    // primary: '#1867C0',
    // 'primary-darken-1': '#1F5592',
    // secondary: '#48A9A6',
    // 'secondary-darken-1': '#018786',
    // error: '#B00020',
    // info: '#2196F3',
    // success: '#4CAF50',
    // warning: '#FB8C00',
  },
  // variables: {
  //   'border-color': '#000000',
  //   'border-opacity': 0.12,
  //   'high-emphasis-opacity': 0.87,
  //   'medium-emphasis-opacity': 0.60,
  //   'disabled-opacity': 0.38,
  //   'idle-opacity': 0.04,
  //   'hover-opacity': 0.04,
  //   'focus-opacity': 0.12,
  //   'selected-opacity': 0.08,
  //   'activated-opacity': 0.12,
  //   'pressed-opacity': 0.12,
  //   'dragged-opacity': 0.08,
  //   'theme-kbd': '#212529',
  //   'theme-on-kbd': '#FFFFFF',
  //   'theme-code': '#F5F5F5',
  //   'theme-on-code': '#000000',
  // }
};

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    defaultTheme: 'hotelQuoteTheme',
    themes: {
      hotelQuoteTheme,
    },
  },
  // locale: {
  //   locale: 'el',
  //   fallback: 'en',
  //   messages: { en, el },
  // },
  date: {
    locale: {
      en: 'en-GB',
      el: 'en-GB',
    },
  },
  defaults: {
    VSelect: {
      variant: 'outlined',
      density: 'comfortable',
    },
    VTextarea: {
      variant: 'outlined',
      density: 'comfortable',
    },
    VTextField: {
      variant: 'outlined',
      density: 'comfortable',
    },
    VDateInput: {
      variant: 'outlined',
      density: 'comfortable',
    },
    VBtn: {
      color: 'primary',
      ripple: false,
      elevation: 0,
      class: 'text-none text-subtitle-2',
    },
    VNumberInput: {
      density: 'compact',
    },
  },
});
