<template>
  <v-app id="hotelquote">

    <AppLoader />
    <AppSnackbar />

    <RouterView/>

  </v-app>
</template>

<script setup>
import { watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/store/auth';
import { useAppStore } from '@/store/app';
import { useUserApi } from './features/User/composables/useUserApi';
import { useUserStore } from './features/User/store';
import { setActivePropertyFunc } from './features/Property/composables/useProperty';

const userStore = useUserStore();
const { setUserEmail, setUserProperties } = userStore;
const { getUser } = useUserApi();
const { userProperties } = storeToRefs(userStore);

const { isAuth0Loading, isAuth0Authenticated } = storeToRefs(useAuthStore());
const { setIsLoading } = useAppStore();

// Function to be executed when the user is authenticated
async function handleAuthenticatedUser() {
  const user = await getUser();
  setUserEmail(user);
  setUserProperties(user);

  const firstProperty = userProperties.value[0];
  if (firstProperty) {
    await setActivePropertyFunc(firstProperty);
  }
}

watch(isAuth0Authenticated, (value) => {
  if (value) {
    setIsLoading(false);
  } else {
    setIsLoading(true);
  }
});

watch(isAuth0Loading, (value) => {
  if (value) {
    setIsLoading(true);
  } else {
    setIsLoading(false);
  }
});

watch([isAuth0Authenticated, isAuth0Loading], async ([isAuthenticated, isLoading]) => {
  if (isAuthenticated === true && isLoading === false) {
    await handleAuthenticatedUser();
  }
});

</script>
