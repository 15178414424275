// Utilities
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAppStore = defineStore('app', () => {
  // STATE
  const isLoading = ref(false);
  const loaderText = ref('');
  const showSnackBar = ref(false);
  const snackBarText = ref('');

  // ACTIONS
  function setIsLoading(val) {
    isLoading.value = val;
  }
  function setShowSnackBar(val) {
    showSnackBar.value = val;
  }
  function setLoaderText(val) {
    loaderText.value = val;
  }
  function setSnackBarText(val) {
    snackBarText.value = val;
  }

  return {
    isLoading,
    setIsLoading,
    loaderText,
    setLoaderText,
    showSnackBar,
    setShowSnackBar,
    snackBarText,
    setSnackBarText,
  };
});
