/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import vuetify from './vuetify';
import router from '../router';
import auth0 from './auth0';
import pinia from '../store';

export function registerPlugins(app) {
  app
    .use(router)
    .use(vuetify)
    .use(pinia)
    .use(auth0); // Auth0 must be after router https://developer.auth0.com/resources/guides/spa/vue/basic-authentication
}
