import axios from 'axios';
import { storeToRefs } from 'pinia';
import { useAppStore } from '../../../store/app';
import { useQuoteStore } from '../../Quote/store';
import { useAuthStore } from '../../../store/auth';

export const usePropertyApi = () => {
  const appStore = useAppStore();

  const getPropertyConfig = async (propertyId) => {
    appStore.setIsLoading(true);

    const { quoteLanguage } = storeToRefs(useQuoteStore());

    const accessToken = await useAuthStore().getAuth0AccessToken();
    const fieldsToFetch = 'fields=name,publicId,config(supportedLanguages,templates,branding(defaultThemeId,themes(themeId,name,description)),userSettingIncludeTaxInPrice)&paths=config';

    try {
      const results = await axios.get(`${import.meta.env.VITE_API_HOST}/properties/${propertyId}?lang=${quoteLanguage.value}&${fieldsToFetch}`, {
        headers: { authorization: `Bearer ${accessToken}` },
      });
      return results.data;
    } catch (error) {
      console.error('Error fetching property:', error);
      throw error;
    } finally {
      appStore.setIsLoading(false);
    }
  };

  return { getPropertyConfig };
};
