// Utilities
import { defineStore } from 'pinia';
import { useAuth0 } from '@auth0/auth0-vue';
import { ref } from 'vue';

export const useAuthStore = defineStore('auth', () => {
  const {
    loginWithRedirect, isLoading, isAuthenticated, getAccessTokenSilently, logout, user,
  } = useAuth0();

  const isAuth0Loading = ref(isLoading);
  const isAuth0Authenticated = ref(isAuthenticated);
  const auth0User = ref(user);

  function loginAuth0() {
    loginWithRedirect({
      authorizationParams: {
        ui_locales: 'en el',
      },

      appState: {
        target: '/authcheck',
      },
    });
  }

  function signupAuth0() {
    loginWithRedirect({
      appState: {
        target: '/authcheck',
      },
      authorizationParams: {
        screen_hint: 'signup',
      },

    });
  }

  function logoutAuth0() {
    logout({
      logoutParams: {
        returnTo: `${window.location.origin}/logout`,
      },
    });
  }

  async function getAuth0AccessToken(ignoreCache) {
    let accessToken;
    if (ignoreCache === true) {
      accessToken = await getAccessTokenSilently({ cacheMode: 'off' });
    } else {
      accessToken = await getAccessTokenSilently();
    }

    return accessToken;
  }

  return {
    loginAuth0, signupAuth0, isAuth0Loading, getAuth0AccessToken, logoutAuth0, isAuth0Authenticated, auth0User,
  };
});
