import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import {
  getDateRangeArray, getNumberOfNightsFromDateRange, getCheckoutFromCheckinAndNights,
} from '@/utils/dateUtils';
import { sampleQuote } from './data/sampleQuote';
import { setQuoteHtmlFromTemplateRendering } from '../composables/templateRenderingUtils';
import { getRoomCombinations } from '../utils/roomCombinationUtils';

export const useQuoteStore = defineStore('quote', () => {
  // STATE
  const checkInDate = ref('');
  const checkOutDate = ref('');
  // const checkInDate = ref('2024-09-20');
  // const checkOutDate = ref('2024-09-22');
  const adults = ref(2);
  const children = ref(0);
  const rooms = ref(1);
  const infants = ref(0);
  const withTextParsing = ref(null);
  const manualAvailability = ref(null);
  const quoteLanguage = ref('en');
  const quoteTemplate = ref('standard');
  const themeId = ref(null);
  const calendarErrorState = ref({
    hasError: false,
    message: '',
  });
  const guestsErrorState = ref({
    hasError: false,
    message: '',
  });

  // Use null to return all template renderings. "web" or "mobile" are alternatives. See request DTO for more options.
  const templateRoleFilter = ref(null);

  const isQuoteFinalised = ref(false);
  // const htmlToCopyAsBlob = ref(null);

  // const openPanels = ref(['editQuotePanel', 'shareQuotePanel']);
  const openPanels = ref(['generateQuotePanel']);

  // const quote = ref(sampleQuote);
  const quote = ref({});

  // GETTERS
  const dateRangeForDatePicker = computed(() => getDateRangeArray(checkInDate.value, checkOutDate.value));
  const numberOfNightsComputed = computed(() => getNumberOfNightsFromDateRange(dateRangeForDatePicker.value));
  const quoteExists = computed(() => Object.keys(quote.value).length > 0);
  const newQuoteHasAvailabilityParamsErrors = computed(() => calendarErrorState.value.hasError || guestsErrorState.value.hasError);
  const hasRoomCombinations = computed(() => quoteExists.value && quote.value.quoteControls.some((control) => control.type === 'roomCombinationsEditor'));
  const isThereAvailabilityForFullPeriod = computed(() => {
    if (!quoteExists.value) return false;
    const roomsFilterEditorControl = quote.value.quoteControls?.find((control) => control.type === 'roomFilter');
    if (roomsFilterEditorControl && roomsFilterEditorControl.data.length > 0) return true;
    return false;
  });
  const roomCombinationsAvailableForTheUserToMake = computed(() => {
    if (!quoteExists.value) return [];

    // We only care if there are no rooms
    if (isThereAvailabilityForFullPeriod.value) return [];

    const availabilityEditorControl = quote.value.quoteControls.find((control) => control.type === 'availabilityEditor');
    const availabilityRooms = availabilityEditorControl.data;
    const { availabilityParams } = quote.value;
    const roomCombinations = getRoomCombinations(availabilityRooms, availabilityParams);

    return roomCombinations;
  });

  // ACTIONS
  function setCheckInDate(date) {
    checkInDate.value = date;
  }

  function setCheckOutDate(date) {
    checkOutDate.value = date;
  }

  function setAdults(count) {
    adults.value = count;
  }

  function setChildren(count) {
    children.value = count;
  }

  function setRooms(count) {
    rooms.value = count;
  }

  function setInfants(count) {
    infants.value = count;
  }

  function setWithTextParsing(text) {
    withTextParsing.value = text;
  }

  function setManualAvailability(data) {
    manualAvailability.value = data;
  }

  function setQuoteLanguage(lang) {
    quoteLanguage.value = lang;
  }

  function setQuoteTemplate(template) {
    quoteTemplate.value = template;
  }

  function setOpenPanels(panels) {
    openPanels.value = panels;
  }
  function setQuote(data, roleFilter = null, isConfirmation = false) {
    quote.value = setQuoteHtmlFromTemplateRendering(data, roleFilter, isConfirmation);

    // Override checking/checkout, adults, children, rooms, infants with the availability params from the quote
    if (data.availabilityParams) {
      checkInDate.value = data.availabilityParams.checkin;
      checkOutDate.value = getCheckoutFromCheckinAndNights(data.availabilityParams.checkin, data.availabilityParams.nights);
      adults.value = +data.availabilityParams.adults;
      children.value = +data.availabilityParams.children;
      rooms.value = +data.availabilityParams.rooms;
      infants.value = +data.availabilityParams.infants;
    }
  }

  function setIsQuoteFinalised(value) {
    isQuoteFinalised.value = value;
  }

  function setCalendarErrorState(hasError, message) {
    calendarErrorState.value = { hasError, message };
  }

  function setGuestsErrorState(hasError, message) {
    guestsErrorState.value = { hasError, message };
  }

  function resetCalendarErrorState() {
    calendarErrorState.value = { hasError: false, message: '' };
  }
  function resetGuestsErrorState() {
    guestsErrorState.value = { hasError: false, message: '' };
  }
  function setQuoteHasAvailabilityParamsErrors(value) {
    newQuoteHasAvailabilityParamsErrors.value = value;
  }
  function setThemeId(id) {
    themeId.value = id;
  }

  function $reset() {
    checkInDate.value = '';
    checkOutDate.value = '';
    adults.value = 2;
    children.value = 0;
    rooms.value = 1;
    infants.value = 0;
    withTextParsing.value = null;
    manualAvailability.value = null;
    quoteLanguage.value = 'en';
    quoteTemplate.value = 'standard';
    openPanels.value = ['generateQuotePanel'];
    quote.value = {};
    resetCalendarErrorState();
    resetGuestsErrorState();
    isQuoteFinalised.value = false;
  }

  function resetGuests() {
    adults.value = 2;
    children.value = 0;
    rooms.value = 1;
    infants.value = 0;
  }

  function resetDates() {
    checkInDate.value = '';
    checkOutDate.value = '';
  }

  // function setHtmlToCopyAsBlob(html) {
  //   htmlToCopyAsBlob.value = html;
  // }

  return {
    // STATE
    checkInDate,
    checkOutDate,
    adults,
    children,
    rooms,
    infants,
    withTextParsing,
    manualAvailability,
    quoteLanguage,
    quoteTemplate,
    openPanels,
    quote,
    calendarErrorState,
    guestsErrorState,
    isQuoteFinalised,
    templateRoleFilter,
    themeId,
    // htmlToCopyAsBlob,

    // GETTERS
    dateRangeForDatePicker,
    numberOfNightsComputed,
    quoteExists,
    newQuoteHasAvailabilityParamsErrors,
    hasRoomCombinations,
    roomCombinationsAvailableForTheUserToMake,
    isThereAvailabilityForFullPeriod,

    // ACTIONS
    setCheckInDate,
    setCheckOutDate,
    setAdults,
    setChildren,
    setRooms,
    setInfants,
    $reset,
    resetGuests,
    resetDates,
    setWithTextParsing,
    setManualAvailability,
    setQuoteLanguage,
    setQuoteTemplate,
    setOpenPanels,
    setQuote,
    setCalendarErrorState,
    setGuestsErrorState,
    setQuoteHasAvailabilityParamsErrors,
    resetCalendarErrorState,
    resetGuestsErrorState,
    setIsQuoteFinalised,
    setThemeId,
    // setHtmlToCopyAsBlob,
  };
});
