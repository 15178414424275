import { cloneDeep, set } from 'lodash';

// const actionQueryParam = useRouteQuery('action');

// Utility function to set quoteHtml based on roleFilter or priority
export function setQuoteHtmlFromTemplateRendering(value, roleFilter = null, isConfirmation = false) {
  // Clone the value to avoid mutating the original object
  const clonedValue = cloneDeep(value);

  const whichTemplateRendering = isConfirmation === true
    ? clonedValue.confirmation.confirmTemplateRendering
    : clonedValue.templateRendering;

  if (clonedValue && whichTemplateRendering && Array.isArray(whichTemplateRendering)) {
    let selectedRendering;

    // If a roleFilter is provided, find the implementation by role
    if (roleFilter) {
      selectedRendering = whichTemplateRendering.find(
        (rendering) => rendering.role === roleFilter,
      );
    }

    // If no roleFilter or no matching role, sort by priority and select the first using array destructuring
    if (!selectedRendering) {
      [selectedRendering] = whichTemplateRendering.sort((a, b) => a.priority - b.priority);
    }

    // Set the content of the selected rendering as confirmHtml or quoteHtml based on isConfirmation flag
    if (isConfirmation) {
      const htmlField = selectedRendering ? selectedRendering.content : '';
      set(clonedValue, 'confirmation.confirmHtml', htmlField);
    } else {
      clonedValue.quoteHtml = selectedRendering ? selectedRendering.content : '';
    }
  } else {
    // Fallback in case templateRendering is not present or is invalid
    const htmlField = isConfirmation ? 'confirmation.confirmHtml' : 'quoteHtml';
    set(clonedValue, htmlField, '');
    clonedValue[htmlField] = '';
  }
  return clonedValue;
}
